<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="actions flex flex-wrap gap-4 items-center my-5">
        <t-select v-model="selectedTemplate" :options="options"></t-select>
      </div>
      <table
        class="
          text-sm
          w-full
          divide-y divide-gray-100
          shadow-sm
          border-gray-200 border
          mb-3
        "
      >
        <thead>
          <tr>
            <th
              v-for="(header, key) in headers"
              class="px-3 py-2 font-semibold text-left bg-gray-100 border-b"
              :key="`${key}-header`"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-100">
          <template v-for="(times, index) in templateData">
            <tr v-for="(cellData, cdi) in times" :key="`${index}${cdi}-row`">
              <td
                v-for="(data, ddi) in cellData"
                :key="`${index}${ddi}-cell`"
                class="px-3 py-2 whitespace-no-wrap align-top border"
              >
                <template v-if="ddi > 1">
                  <div
                    v-for="(row, jbi) in data"
                    :key="`${index}${jbi}-data`"
                    class="job-item"
                  >
                    <div class="flex items-center">
                      <span class="font-bold mr-2">
                        {{ row.template_job.job.NAME || "" }}
                      </span>
                      <button
                        v-if="row.is_custom"
                        class="text-blue-700"
                        @click="deleteTemplateHour(row.id)"
                        :disabled="loading"
                      >
                        &#10006;
                      </button>
                    </div>
                    <div>({{ row.from }} - {{ row.until }})</div>
                    <div
                      :class="{
                        'text-red-500 font-bold': row.user,
                        'text-blue-500': !row.user,
                        'cursor-pointer': true,
                      }"
                      @click="showChangeUserModal(row)"
                    >
                      {{ row.user ? row.user.NAME : $t("preferred_empty") }}
                    </div>
                  </div>
                </template>
                <div v-else>
                  {{ !cdi && !ddi ? data : cdi && !ddi ? "" : data }}
                </div>
              </td>
            </tr>
            <tr :key="`${index}-divider`">
              <td
                colspan="9"
                class="border border-solid border-gray-300 p-0"
              ></td>
            </tr>
          </template>
          <tr>
            <td colspan="2"></td>
            <td v-for="(nth, index) in 7" :key="index" class="px-1 py-2">
              <label>{{ $t("start") }}</label>
              <t-select
                v-model="forms.start[nth]"
                class="w-full"
                :options="timeSlots.start"
              ></t-select>
            </td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td v-for="(nth, index) in 7" :key="index" class="px-1 py-2">
              <label>{{ $t("ending") }}</label>
              <t-select
                v-model="forms.end[nth]"
                class="w-full"
                :options="timeSlots.end"
              ></t-select>
            </td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td v-for="(nth, index) in 7" :key="index" class="px-1 py-2">
              <label>{{ $t("job_type") }}</label>
              <t-select
                v-model="forms.job[nth]"
                class="w-full"
                :options="jobs"
                value-attribute="JOBID"
                text-attribute="NAME"
              ></t-select>
            </td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td v-for="(nth, index) in 7" :key="index" class="px-1 py-2">
              <t-button
                @click="addEmployee(nth)"
                class="w-full"
                variant="primary"
                :disabled="loading"
              >
                {{ $t("to_add") }}
              </t-button>
            </td>
          </tr>
        </tbody>
      </table>
      <t-modal
        v-model="showChangeEmployee"
        :header="$t('select_preferred_employee')"
      >
        <t-select
          class="w-full"
          v-model="selectedChangeEmployee"
          :options="users"
          value-attribute="ID"
          text-attribute="NAME"
        ></t-select>
        <template v-slot:footer>
          <div class="flex justify-end">
            <t-button
              type="button"
              class="text-sm mr-2"
              variant="primary"
              :disabled="loading"
              @click="updateEmployee"
            >
              {{ $t("choose") }}
            </t-button>
            <t-button
              type="button"
              class="text-sm"
              :disabled="loading"
              @click="showChangeEmployee = false"
              >{{ $t("close") }}</t-button
            >
          </div>
        </template>
      </t-modal>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { actions, getters } from "@/constants/state";
import { getTimeSlotOptions } from "@/utils/misc";
import { getJobsByLocation } from "@/services/job-service";
import { create, update, remove } from "@/services/template-hour-service";
import { getUsersByJob } from "@/services/user-service";
import { getTemplateJob } from "@/services/template-hour-service";
import { chain, get, map, sortBy } from "lodash";
import moment from "@/utils/moment-utc";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Layout },
  data: () => ({
    users: [],
    jobs: [],
    selectedTemplate: null,
    newTemplate: null,
    selectedChangeEmployee: null,
    selectedChangeTemplateHour: null,
    forms: {
      start: {},
      end: {},
      job: {},
    },
    showChangeEmployee: false,
    loading: false,
  }),
  async mounted() {
    this.selectedTemplate = get(this.templates, "[0].id");

    try {
      const { data } = await getJobsByLocation();
      this.jobs = data;
      this.forms.start = { ...this.formValue("07:00") };
      this.forms.end = { ...this.formValue("07:00") };
      this.forms.job = { ...this.formValue(get(this.jobs, "0.JOBID")) };
    } catch (error) {
      console.error("getJobsByLocation", error);
    }
  },
  methods: {
    ...mapActions({
      getPageData: actions.TEMPLATES_FETCH_PAGE_DATA_ACTION,
      getUserJobs: actions.JOBS_FETCH_USER_JOBS_ACTION,
    }),
    async fetchPageData(templateId) {
      try {
        this.loading = true;
        await this.getPageData({ templateId, isCustom: true });
      } catch (error) {
        console.error(actions.TEMPLATES_FETCH_PAGE_DATA_ACTION, error);
      }

      this.loading = false;
    },
    async showChangeUserModal(payload) {
      try {
        this.loading = true;
        const { data } = await getUsersByJob(
          get(payload, "template_job.job.JOBID")
        );
        this.users = [
          {
            ID: null,
            NAME: this.$t("preferred_empty"),
          },
          ...data,
        ];
        this.selectedChangeTemplateHour = get(payload, "id");
        this.selectedChangeEmployee = get(payload, "user.ID");
        this.showChangeEmployee = true;
      } catch (error) {
        console.error("getUsersByJob", error);
      }

      this.loading = false;
    },
    async addEmployee(day) {
      try {
        this.loading = true;
        const { data } = await getTemplateJob(
          this.forms.job[day],
          this.locationId
        );

        await create({
          template_id: this.selectedTemplate,
          template_job_id: get(data, "0.template_job_id"),
          from: this.forms.start[day],
          until: this.forms.end[day],
          day,
          is_custom: 1,
          custom_horeca_location_id: this.locationId,
        });
        await this.fetchPageData(this.selectedTemplate);
      } catch (error) {
        console.error("addEmployee", error);
      }

      this.loading = false;
    },
    async updateEmployee() {
      try {
        this.loading = true;
        await update(this.selectedChangeTemplateHour, {
          user_id: this.selectedChangeEmployee,
        });
        this.selectedChangeTemplateHour = null;
        this.selectedChangeEmployee = null;
        await this.fetchPageData(this.selectedTemplate);
        this.showChangeEmployee = false;
      } catch (error) {
        console.error("updateEmployee", error);
      }

      this.loading = false;
    },
    async deleteTemplateHour(id) {
      try {
        this.loading = true;
        await remove(id);
        await this.fetchPageData(this.selectedTemplate);
      } catch (error) {
        console.error("deleteTemplateHour", error);
      }

      this.loading = false;
    },
    formValue(value) {
      return {
        1: value,
        2: value,
        3: value,
        4: value,
        5: value,
        6: value,
        7: value,
      };
    },
  },
  computed: {
    ...mapGetters({
      isAdminOrSuperUser: getters.AUTH_IS_ADMIN_OR_SUPERUSER_GETTER,
      templates: getters.TEMPLATES_GETTER,
      templatesPageData: getters.TEMPLATES_PAGE_DATA_GETTER,
      userId: getters.AUTH_USER_ID_GETTER,
      locationId: getters.DOMAIN_LOCATION_ID_GETTER,
    }),
    templateData() {
      return chain(this.templatesPageData)
        .map((template) => {
          const collected = [];
          let iteration = 0;

          for (const time in template.times) {
            collected[iteration] = [
              get(template, "job.NAME"),
              moment(time, "HH:mm:ss").format("HH:mm"),
              ...map(template.times[time], (jobs) => jobs),
            ];
            iteration++;
          }

          return collected;
        })
        .map((items) =>
          sortBy(items, [
            function (o) {
              return o[1];
            },
          ])
        )
        .value();
    },
    options() {
      return map(this.templates, (template) => ({
        value: template.id,
        text: template.name,
      }));
    },
    headers() {
      return [
        "",
        "",
        this.$i18n.t("monday"),
        this.$i18n.t("tuesday"),
        this.$i18n.t("wednesday"),
        this.$i18n.t("thursday"),
        this.$i18n.t("friday"),
        this.$i18n.t("saturday"),
        this.$i18n.t("sunday"),
      ];
    },
    timeSlots() {
      return getTimeSlotOptions();
    },
  },
  watch: {
    selectedTemplate: function (value) {
      this.fetchPageData(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  & > * {
    max-height: 37.2px;
    margin-right: 0.5rem;
  }
}

.job-item:not(:first-child) {
  margin-top: 0.5rem;
}
</style>
